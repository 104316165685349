<template>
<b-card title="Script Run">
  <b-card-text>Page to run Script to handle "Duplicate Model in DO list" and "No Item for DO is Available" issues</b-card-text>
  <b-col cols="12">
    <b-row>
      <b-col md="6">
        <strong>DO Number</strong>
        <b-form-input
          v-model="doNumber"
          type="search"
          placeholder="Enter DO"
          style="float: right; margin-bottom: 30px;" />

      </b-col>
      <b-col md="6">
        <strong>Script to Run</strong>
        <br />
        <v-select
          v-model="script"
          :options="['duplicate','item']"
          style="margin-top:5px !important;"
          class="mb-1" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <strong>Write Update</strong>
        <br />
        <strong style="color:red;">If true, data will be processed. If false, data will not be processed</strong>
        <br />
        <v-select
          v-model="write"
          :options="['false','true']"
          style="margin-top:5px !important;"
          class="mb-1" />

      </b-col>
      <b-col cols="6">
        <strong>Result</strong>
        <br />
        <ul v-for="(item, index) in result">
          <li>{{item}}</li>
        </ul>
        
      </b-col>

    </b-row>
    <b-row>
      <b-col cols="4">
        <b-button
          :disabled="buttonDisable"
          variant="success"
          @click="runScript">
          Run Script
        </b-button>
      </b-col>
    </b-row>
    
  </b-col>

</b-card>
</template>

<script>
import {
  BCard,
  BCardText
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "@/axios";

export default {
  components: {
    BCard,
    BCardText,
    "v-select": vSelect,

  },
  data() {
    return {
      loading: false,
      script: "duplicate",
      doNumber: "",
      write: "false",
      result: [],
      options: [],
      buttonDisable:false,
    };
  },
  mounted() {},
  methods: {

    async runScript() {
      console.info("script ", this.script);
      console.info("doNumber ", this.doNumber);
      console.info("write ", this.write)
      if(this.script==null||this.write==null||this.doNumber==""){
        this.$bvToast.toast(`DONumber/Write/Script not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return
      }
      var result;
      this.buttonDisable=true;
      var writeRequest;
      if(this.write=="true"){
        writeRequest=true;
      }
      console.info('writeRequest ',writeRequest)
      try{
        if(this.script=="duplicate"){
        result = await axios.post(`sales-and-purchase/drcc/duplicate-check`,{
          doNumber:this.doNumber,
          write:writeRequest
        },{timeout:120000})
        this.result=result.data.logData
      }else{
        result = await axios.post(`sales-and-purchase/drcc/do-check`,{
          doNumber:this.doNumber,
          write:writeRequest
        },{timeout:120000})
        this.result=result.data.data
      }
      }catch(err){
        this.$bvToast.toast(`Error running script, please try again`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.buttonDisable=false;
        return
      }
      if(this.write=="true"){
        this.$bvToast.toast(`Run Write Script Successful`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
        this.write="false"
      }else{
        this.$bvToast.toast(`Run Check Script Successful`, {
        title: "Success",
        variant: "warning",
        solid: true,
      });
      }
      
      this.buttonDisable=false;


      
    }
  },
  computed: {},
};
</script>

<style></style>
